.notification {
  width: 200px;
  margin: 10px 5px;
  font-size: 14px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, .5);
  border-radius: 2px;
  animation: calc(var(--value) * 0.1) ease-out calc(var(--value) * 0.9) fadeOut;
  z-index: 1000;
}

/* Success */
.notification.success {
  background: #cdfccd;
}

.notification.success .timer {
  background: #86aa86;
}

/* Error */
.notification.error {
  background: #ffe3e3;
}

.notification.error .timer {
  background: #c28585;
}

.inner-wrapper {
  padding: 5px;
}

.timer {
  width: 100%;
  height: 2px;
  animation: progress var(--value) linear;
}

.notification-header {
  text-transform: capitalize;
  font-size: 12px;
}

.notification-body {
  font-weight: bold;
}

@keyframes fadeOut {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
}

@keyframes progress {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
