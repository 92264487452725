.tooltip-component {
  opacity: 1;
  max-width: 400px;
  cursor: pointer;
  position: fixed;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: left;
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 20px;
  color: rgba(51, 77, 110, 1);
  transition: opacity 0.1s ease-out;
  z-index: 99;
}
