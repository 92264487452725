.sidebar {
  height: 100%;
  overflow-x: hidden;
  overflow-y: initial;
  padding: 0 0 0.5rem;
  width: 250px;
  float: left;
  position: fixed;
  z-index: 1038;
  background-color: #343a40;
  color: #c2c7d0;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.sidebar a {
  color: #c2c7d0;
}

.sidebar a:hover,
.sidebar a:focus {
  text-decoration: none;
}

.content-wrapper {
  height: 100%;
  margin-left: 250px;
  background-color: #f4f6f9;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  color: #6c757d;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.sidebar .nav .nav-item  .nav-link.active {
  background-color: #007bff;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.nav-link {
  margin-bottom: .2rem;
}

.nav-item {
  margin-bottom: 0;
}

.nav-item .nav-icon {
  margin-left: .05rem;
  font-size: 1.2rem;
  margin-right: .2rem;
  text-align: center;
  width: 1.6rem;
}

.sidebar .nav-item:hover > .nav-link,
.sidebar .nav-item > .nav-link:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

