:root {
  --white: #ffffff;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --black: #000;
}

.loading-line {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: backgroundMovement;
  animation-timing-function: linear;
  background: var(--grey-200);
  background: linear-gradient(
    90deg,
    var(--grey-300) 0%,
    var(--grey-200) 80%,
    var(--grey-300) 100%
  );
  background-size: 200%;
  height: 100%;
}

@keyframes backgroundMovement {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}
