.range-slider__inner {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 10px 14px;
  background: #cfebfc;
  box-shadow: 0 0 1px #c2cfe0;
  user-select: none;
}

.range-slider__thumb-right,
.range-slider__thumb-left {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  height: 18px;
  width: 6px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDYgMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjk1OTExNCA0LjU1MjQ2TDQuNjUzMDQgMC45OTI1NTZDNC45NzA1OSAwLjY4NjUyNyA1LjUgMC45MTE1NjcgNS41IDEuMzUyNThWMTYuOTI5N0M1LjUgMTcuMzQ4MSA1LjAxNjg2IDE3LjU4MTUgNC42ODkxNyAxNy4zMjE0TDEuMDY3NTEgMTQuNDQ3MUMwLjcwODk4OCAxNC4xNjI1IDAuNSAxMy43Mjk4IDAuNSAxMy4yNzIxVjUuNjMyNTNDMC41IDUuMjI1MSAwLjY2NTc0IDQuODM1MTkgMC45NTkxMTQgNC41NTI0NloiIGZpbGw9IndoaXRlIiBzdHJva2U9IiNDMkNGRTAiLz4KPC9zdmc+Cg==") left center no-repeat;
  background-size: cover;
  cursor: grab;
}

/* cursor over all slider, so that if the cursor moves out of the thumb, it's still grabbing */
.range-slider_dragging,
.range-slider_dragging .range-slider__thumb-right,
.range-slider_dragging .range-slider__thumb-left {
  cursor: grabbing;
}

.range-slider__thumb-left {
  left: 0;
  margin-left: -6px;
}

.range-slider__thumb-right {
  right: 0;
  transform: rotate(180deg);
  margin-right: -6px;
}

.range-slider__progress {
  background: #109cf1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.range-slider {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 60px;
}

.range-slider > span {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #707683;
  width: 60px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.range-slider > span:first-child {
  text-align: right;
  left: 0;
}

.range-slider > span:last-child {
  right: 0;
}
